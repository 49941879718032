:root {
  --mainblue: #8befff;
}
html {
  cursor: url("./yes.png"), auto !important;
}
body {
  margin: 0;
  font-family: Chalkduster, Arial, serif;
  font-style: normal;
  /* background-color: var(--mainblue) !important; */
  background-image: url(../public/photos/background_sm.jpg) !important;

  /* -webkit-font-smoothing: antialiased; */
  /* -moz-osx-font-smoothing: grayscale; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.choice {
  padding: 30px;
  border-radius: 20px;
  background-color: white;
  color: grey;
  height: 100px;
  margin: 10px;
}

.choice:hover {
  background-color: lightblue;
  cursor: pointer;
}
